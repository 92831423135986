<script>
import countTo from "vue-count-to";
//import axios from 'axios';
const moment = require('moment');

/**
 * Sales-analytics component
 */
export default {
    components: {
        countTo
    },
    props: {
        AllData: {
            type: Object,
        }
    },
    data() {
        return {
            series: [],
            chartOptions: {
            chart: {
                stacked: false,
                toolbar: {
                    show: false
                },
            },
            stroke: {
                width: [0, 2, 4],
                curve: 'smooth'
            },
            plotOptions: {
                bar: {
                    columnWidth: '30%'
                }
            },
             dataLabels: {
                    enabled: false,
                },
            colors: ['#5b73e8', '#84f6fa', '#f1b44c'],
            fill: {
                opacity: [0.85, 0.25, 1],
                gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: "vertical",
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100, 100]
                }
            },
            labels: [],
            xaxis: {
                type: 'datetime',
                labels: {
                format: 'MMM'
                },
                tooltip: {
                    x: {
                        format: 'DD'
                    }
                },


            },
            yaxis: {
                title: {
                    text: 'Per Bulan',
                },
                labels: {
                    text: 'MM'
                },
            },
            tooltip: {
                shared: true,
                intersect: false,

                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {

                            return y.toFixed(0) + " points";
                        }
                        return y;

                    },

                }
            },
            grid: {
                borderColor: '#f1f1f1'
            }

            },
            viewPelangganBaru: {
                bulan: "",
                pelangganBaru: 0,
                growthPelangganBaru: 0
            },
            growthPelangganBaru: 0,
            DataSetApePelangganBaru: [],
            DataSetApePelangganMasuk: [],
            viewPelangganMasuk: 0,
            viewPelangganMasukEndval: 0,
            viewPelangganBaruEndval: 0,
            konversiGrowth: 0,
            clientGrupDropdown: [],
            labelGroupDropdown: "All",
            isDataLoaded: false,
            holderProps:null,
            formattedDates: null
           
            
        };
    },
    async created() {
        try {
            await this.getPelangganDataLocalByClientGrup();
            await this.getChartDataPelangganMasuk();
            await this.updateChart();
            
            
        } catch (error) {
            console.error("Error during mounted:", error);
        }
    },
    methods: {
        
         getChartDataPelangganMasuk() {
            this.viewPelangganMasuk = parseInt(this.AllData.returnDataAllClient.totalPelanggaMasuk)
            this.viewPelangganMasukEndval = this.AllData.returnDataAllClient.totalPelanggaMasuk
            // try {
            //     const response = await axios.get(`${process.env.VUE_APP_URL_FEATHERS}custumer-charts?customerChart=true`);
            //     this.viewPelangganMasuk = parseInt(response.data.viewDataPelanggan.totalPelangganMasuk);
            //     this.viewPelangganMasukEndval = response.data.viewDataPelanggan.totalPelangganMasuk;
            //     console.log('this.viewPelangganMasuk',this.viewPelangganMasuk);
            //     console.log('this.viewPelangganMasukEndval',this.viewPelangganMasukEndval);
                
            // } catch (error) {
            //     console.error("Error fetching pelanggan masuk data:", error);
            // }
        },
        async getPelangganDataLocalByClientGrup() {

                    this.DataSetApePelangganBaru = this.AllData.returnDataAllClient.pelangganbaru;
                    this.allDataSet = this.AllData;
                    this.clientGrupDropdown = this.AllData.clientGrupArray;
                    this.labelGroupDropdown = "All Client Grup";
                    this.viewPelangganBaruEndval = parseInt(this.AllData.returnDataAllClient.totalpelangganBaru);
                    this.DataSetApePelangganMasuk = this.AllData.returnDataAllClient.pelangganMasuk;
                    this.viewPelangganMasuk = parseInt(this.AllData.returnDataAllClient.totalPelangganMasuk);
                    this.viewPelangganMasukEndval = parseInt(this.AllData.returnDataAllClient.totalPelangganMasuk);
                    this.konversiGrowth = (this.viewPelangganBaruEndval / this.viewPelangganMasuk) * 100;
                    this.formattedDates = this.AllData.returnDataAllClient.bulan
               
        },
        async updateChart() {
            this.series = []
            this.series.push({
                name: 'Pelanggan Baru',
                type: 'column',
                data: this.DataSetApePelangganBaru
            });
            this.series.push({
                name: 'Pelanggan Masuk',
                type: 'area',
                data: this.DataSetApePelangganMasuk
            });
            this.chartOptions = Object.assign({}, this.chartOptions, {
            labels: this.formattedDates.map(date => moment(date).format('MM/DD/YYYY'))
        })
        },

        async getDataByDropdown(test) {
            
        this.labelGroupDropdown = test
       
        },
       

    },
    watch: {
  labelGroupDropdown: {
    
    handler(test) {
      
      if (test === "All Client Grup") {
        this.getPelangganDataLocalByClientGrup().then(() => {
            this.getChartDataPelangganMasuk()
          this.updateChart();
        });
      } else {
        const currentGroup = this.allDataSet.arrayHasil.find(group => group.groupcode === test);
        if (currentGroup) {
          const indexBulan = moment().startOf('month').format('M') - 1;
          this.DataSetApePelangganBaru = currentGroup.pelangganbaru;
          this.viewPelangganBaruEndval = parseInt(currentGroup.totalpelangganBaru[indexBulan]);
          this.viewPelangganMasuk = parseInt(currentGroup.totalPelangganMasuk[indexBulan]);
          this.konversiGrowth = (this.viewPelangganBaruEndval / this.viewPelangganMasuk) * 100;
          this.updateChart();
        }
      }
    }
  }
}
};
</script>

<template>
    <div class="col-md-6" >
        <div class="card">
            <div class="card-body">
                <div class="float-end">
                    <b-dropdown variant="white" toggle-class="text-reset p-0" menu-class="dropdown-menu-end"
                        v-bind:text="(labelGroupDropdown == 'All' ? 'All' : labelGroupDropdown)">
                        <template v-slot:button-content>
                            <span class="fw-semibold">Client Grup:</span>
                            <span class="text-muted">
                                {{ labelGroupDropdown }}
                                <i class="mdi mdi-chevron-down ms-1"></i>
                            </span>
                        </template>
                        <b-dropdown-item href="#" @click="getPelangganDataLocalByClientGrup">All Client Grup</b-dropdown-item>
                        <b-dropdown-item href="#" v-for="clientGrup in clientGrupDropdown" :key="clientGrup"
                            @click="getDataByDropdown(clientGrup)">{{ clientGrup }}</b-dropdown-item>
                    </b-dropdown>
                </div>
                <h4 class="card-title mb-4">Pelanggan Baru Analytics</h4>

                <div class="mt-1">
                    <ul class="list-inline main-chart mb-0">
                        <li class="list-inline-item chart-border-left me-0 border-0">
                            <h3 class="text-primary">
                                <span data-plugin="counterup" class="font-size-22">
                                    <countTo :startVal="0" :endVal="viewPelangganBaruEndval" :duration="2000"></countTo>
                                </span>
                                <span class="text-muted d-inline-block font-size-14 ms-3">Pelanggan Baru</span>
                            </h3>
                        </li>
                        <li class="list-inline-item chart-border-left me-0 ">
                            <h3>
                                <span data-plugin="counterup" class="font-size-22">
                                    <countTo :startVal="0" :endVal="viewPelangganMasuk" :duration="2000"></countTo>
                                </span>
                                <span class="text-muted d-inline-block font-size-14 ms-3">Pelanggan Masuk</span>
                            </h3>
                        </li>
                    </ul>
                   
                </div>
                <div class="mt-1" >
                    <div style="padding-bottom: 10px;"></div>
                    <apexchart type="bar" class="apex-charts" dir="ltr" height="339" :options="chartOptions"
                        :series="series"></apexchart>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.card {
    border: 1px solid #ebebeb;
}

</style>