<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Stat from "@/components/widgets/stat";
import SalesAnalytics from "./sales-analytics";
import invoicesAnalytics from "./invoices-analytics";
// import SellingProduct from './selling-product';
import TopUsers from './top-users';
import Activity from './activity';
import SocialSource from './social-source';
import Loading from 'vue-loading-overlay';

import axios from 'axios';
const moment = require('moment');



export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Stat,
    SalesAnalytics,
    // SellingProduct,
    Loading,
    TopUsers,
    Activity,
    SocialSource,
    invoicesAnalytics,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "CRM",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      //var untuk widget
      testing: [],

      viewPelangganBaru: {
        pelangganBaru: 0,
        growthPelangganBaru: 0
      },
      growthPelangganBaru: {

      },

      invoices: {
        totalInvoice: 0,
        totalPaidInvoices: 0
      },
      persenPaid: [],
      persenGrowthInvoice: 0.00,
      persenGrowthInvoicePaid: 0.00,
      persenGrowthPelangganBaru: 0.00,
      clientgrupAdmin: [],
      viewTotalInvoice: 0,
      viewTotalPaidInvoices: 0,

      viewGrowthMountly: {
        GrowthMountlytotal: 0,
        GrowthMountlyPaided: 0,
      },
      clientGrupGrowthMountly: {
      },
      pelangganMasukDataSet: {
        viewPelangganMasuk: [],
        viewPelangganMasukEndval: 0,
        persenGrowthPelangganMasuk: 0,
      },
      //end var untuk widget

      //var invoice analytc
      viewTotalInvoiceAnalysis: 0,
      viewTotalPaidInvoicesAnalysis: 0,
      viewTotalInvoiceYearAnalysis: 0,
      viewTotalInvoicePaidYearAnalysis: 0,
      DataSetInvoicesAnalysis: [],
      DataSetInvoicesPaidAnalysis: [],
      konversiDataSetAnalysis: 0.00,
      // end var invoice analytc

      //var pelanggan analytic
      growthPelangganBaruAnalysis: 0,
      DataSetApePelangganBaruAnalysis: [],
      DataSetApePelangganMasukAnalysis: [],
      viewPelangganMasukAnalysisAnalysis: [],
      viewPelangganMasukEndvalAnalysis: 0,
      konversiGrowthAnalysis: 0,
      isDataLoaded:false,
      allDataSet: null,
      isLoading: false,
      invoicesParent:null
      //end var pelanggan analytic

    };
  },
  async mounted() {
    // await this.beforeCornStart()
    // awat this.CornScheudleRefresh()
    //await this.getInvoice()
    
    
    
  },
  async created() {
  this.isLoading = true;
  console.log('Loading started');
  try {
    // Panggil metode dan tunggu hingga selesai
    await this.getInvoicesDataLocalByClientGrup(); // Tunggu hingga metode pertama selesai
    console.log('Data from getInvoicesDataLocalByClientGrup fetched');
    
    await this.getInvoice(); // Tunggu hingga metode kedua selesai
    console.log('Data from getInvoice fetched');
    
    // Jika ada metode lain yang perlu dipanggil setelah ini, bisa dipanggil di sini
  } catch (error) {
    console.error('Terjadi kesalahan saat mengambil data:', error);
  }
  this.isLoading = false; // Set loading ke false setelah semua selesai
  console.log('Loading finished');
},

  methods: {
    //methods untuk 4 widget card 
    async getRecentPelangganBaru() {
      var clientobj = this.$store.state.authfack.user.clientgrup
      for (let index = 0; index < clientobj.length; index++) {
        let code = clientobj
        
        await axios.get(process.env.VUE_APP_URL_FEATHERS + 'customer-data?widget=' + code).then(response => {
          this.growthPelangganBaru = response.data.dataPelanggan
          this.viewPelangganBaru.pelangganBaru = this.viewPelangganBaru.pelangganBaru + response.data.dataPelanggan.oneMountData
          this.viewPelangganBaru.growthPelangganBaru = this.viewPelangganBaru.growthPelangganBaru + response.data.dataPelanggan.TwoMountData
        });
      }
    },
     
    async getInvoice() {
   // Menyatakan loading dimulai
  try {
    const response = await axios.get(process.env.VUE_APP_URL_FEATHERS + 'invoice' + '?invoiceTotal=true');
    this.invoicesParent= response
    // Jika Anda masih memanggil API lain, lakukan di sini
  } catch (error) {
    console.error('Error fetching invoices:', error);
  } 
},
    async getGrowthMountly() {
      var clientobj = this.$store.state.authfack.user.clientgrup
      var code
      var pastMonth = moment().subtract(30, 'days');
      var past2Month = moment().subtract(60, 'days')

      for (let index = 0; index < clientobj.length; index++) {
        code = clientobj[index].code
        await axios.get(process.env.VUE_APP_URL_FEATHERS + 'invoice' + '?createdAt[$lt]=' + pastMonth + '&&clientGroup[$in][]=' + code + '&&createdAt[$gt]=' + past2Month).then(response => {

          this.clientGrupGrowthMountly = response.data.dataInvoices

          this.viewGrowthMountly.GrowthMountlytotal = this.viewGrowthMountly.GrowthMountlytotal + this.clientGrupGrowthMountly.dataGrowthInvoice
          this.viewGrowthMountly.GrowthMountlyPaided = this.viewGrowthMountly.GrowthMountlyPaided + this.clientGrupGrowthMountly.dataGrowthInvoicePaid
        });
      }


      let hitungPersen = this.viewTotalInvoice / this.viewGrowthMountly.GrowthMountlytotal * 100 - 100
      hitungPersen = parseFloat(hitungPersen)

      this.persenGrowthInvoice = hitungPersen
      let hitungPersenPaid = this.viewTotalPaidInvoices / this.viewGrowthMountly.GrowthMountlyPaided * 100 - 100

      hitungPersenPaid = parseFloat(hitungPersenPaid)
      this.persenGrowthInvoicePaid = hitungPersenPaid

      this.persenGrowthPelangganBaru = this.viewPelangganBaru.pelangganBaru / this.viewPelangganBaru.growthPelangganBaru * 100 - 100
      this.persenGrowthPelangganBaru = parseFloat(this.persenGrowthPelangganBaru)

      if (this.pelangganMasukDataSet.viewPelangganMasuk.pelangganMasukBulanLalu == 0) {
        this.pelangganMasukDataSet.persenGrowthPelangganMasuk = this.pelangganMasukDataSet.viewPelangganMasuk.pelangganMasuk * 100

      }
      else {
        this.pelangganMasukDataSet.persenGrowthPelangganMasuk = this.pelangganMasukDataSet.viewPelangganMasuk.pelangganMasuk / this.pelangganMasukDataSet.viewPelangganMasuk * 100 - 100
      }

      this.pelangganMasukDataSet.persenGrowthPelangganMasuk = parseFloat(this.pelangganMasukDataSet.persenGrowthPelangganMasuk)
      
    },
    async getChartDataPelangganMasuk() {
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'custumer-charts?customerChart=true').then(response => {

        
        this.pelangganMasukDataSet.viewPelangganMasuk = response.data.viewDataPelanggan
        this.pelangganMasukDataSet.viewPelangganMasukEndval = response.data.viewDataPelanggan.pelangganMasuk




      });
    },
    //end  method widget
    //methods untuk invoices analysis
    // async getInvoiceanalysis() {
    //   var clientobj = this.$store.state.authfack.user.clientgrup
    //   var Analytics
    //   var pastMonth = moment().subtract(30, 'days');

    //   await axios.get(process.env.VUE_APP_URL_FEATHERS + 'invoice' + '?invoiceTotal=true').then(response => {
      
    //     this.viewTotalInvoiceYearAnalysis = response.data.totalInvoices
    //     this.viewTotalInvoicePaidYearAnalysis = response.data.totalInvoicesPaid

      

    //   });



    //   for (let index = 0; index < clientobj.length; index++) {
    //     Analytics = clientobj[index].code
    //     await axios.get(process.env.VUE_APP_URL_FEATHERS + 'invoice' + '?createdAt[$gt]=' + pastMonth + '&&clientGroup[$in][]=' + Analytics + '&&Analytics= true').then(response => {
    //       this.invoices = response.data.dataInvoices
    //       this.viewTotalInvoiceAnalysis = this.viewTotalInvoiceAnalysis + this.invoices.totalInvoicePerClient
    //       this.viewTotalPaidInvoicesAnalysis = this.viewTotalPaidInvoicesAnalysis + this.invoices.totalPaidInvoicesPerClient


    //       for (let index2 = 0; index2 < response.data.dataPelanggan.length; index2++) {
    //         let countInvoices = 0
    //         let countInvoicesPaid = 0

    //         countInvoices = response.data.dataPelanggan[index2].invoicesTotal
    //         this.viewTotalInvoiceYearAnalysis = this.viewTotalInvoiceYearAnalysis + countInvoices

    //         countInvoicesPaid = response.data.dataPelanggan[index2].invoicesTerbayarTotal
    //         this.viewTotalInvoicePaidYearAnalysis = this.viewTotalInvoicePaidYearAnalysis + countInvoicesPaid

    //         if (this.DataSetInvoicesAnalysis.length < 12) {
    //           this.DataSetInvoicesAnalysis.push(response.data.dataPelanggan[index2].invoicesTotal)
    //           this.DataSetInvoicesPaidAnalysis.push(response.data.dataPelanggan[index2].invoicesTerbayarTotal)
    //         } else if (this.DataSetInvoicesAnalysis[index2] != null) {
    //           this.DataSetInvoicesAnalysis[index2] = this.DataSetInvoicesAnalysis[index2] + response.data.dataPelanggan[index2].invoicesTotal
    //           this.DataSetInvoicesPaidAnalysis[index2] = this.DataSetInvoicesPaidAnalysis[index2] + response.data.dataPelanggan[index2].invoicesTerbayarTotal
    //         }

    //       }


    //     });


    //   }
    //   this.konversiDataSetAnalysis = this.viewTotalInvoicePaidYearAnalysis / this.viewTotalInvoiceYearAnalysis * 100

    // },
    //end methods untuk invoices analysis

    //method untuk pelanggan analisis
    async getChartDataPelangganBaruAnalysis() {
      
        await axios.get(process.env.VUE_APP_URL_FEATHERS + 'customer-data?Analytics=true').then(response => {
         
          this.growthPelangganBaruAnalysis = this.growthPelangganBaruAnalysis + response.data.totalPelanggan

          let looping = 0
          for (let index2 = 0; index2 < response.data.dataPelanggan.length; index2++) {
            if (this.DataSetApePelangganBaruAnalysis.length < 12) {
              this.DataSetApePelangganBaruAnalysis.push(response.data.dataPelanggan[index2].pelangganBaru)


            } else if (looping < 12) {
              this.DataSetApePelangganBaruAnalysis[index2] = this.DataSetApePelangganBaruAnalysis[index2] + response.data.dataPelanggan[index2].pelangganBaru
            }
           
            
          }
        


        });


    },
    async getChartDataPelangganMasukAnalysis() {
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'custumer-charts?customerChart=true').then(response => {
        

        this.viewPelangganMasukAnalysis = response.data.viewDataPelanggan
        this.viewPelangganMasukEndvalAnalysis = response.data.viewDataPelanggan.pelangganMasuk


        for (let index = 0; index < 12; index++) {
          this.DataSetApePelangganMasukAnalysis.push(response.data.dataPelanggan.data[index].pelangganBaruMasuk)

        }

        this.konversiGrowthAnalysis = this.growthPelangganBaruAnalysis / this.viewPelangganMasukEndvalAnalysis * 100


      });
    },


    async loadDataDashboardWidget() {
      await this.getRecentPelangganBaru()
      await this.getInvoice()
      await this.getChartDataPelangganMasuk()
      //await this.getInvoiceanalysis()
      await this.getChartDataPelangganBaruAnalysis()
      await this.getChartDataPelangganMasukAnalysis()
      await this.getGrowthMountly()
    },

    async loadDataDashboardApex() {
      // await this.updateDataApexChart()
      // //await this.getInvoiceanalysis()
      // await this.getChartDataPelangganBaruAnalysis()
      // await this.getChartDataPelangganMasukAnalysis()
      

    },

    async postDataToDbWidget() {
      let dataWidget = {
        pelangganMasukTotal: this.pelangganMasukDataSet.viewPelangganMasukEndval,
        pelangganMasukTotalGrowth: this.pelangganMasukDataSet.persenGrowthPelangganMasuk,
        pelangganBaruTotal: this.viewPelangganBaru.pelangganBaru,
        pelangganBaruTotalGrwoth: this.persenGrowthPelangganBaru,
        invoiceTotal: this.viewTotalInvoice,
        invoiceTotalGrowth: this.persenGrowthInvoice,
        paidInvoiceTotal: this.viewTotalPaidInvoices,
        paidInvoiceTotalGrowth: this.persenGrowthInvoicePaid
      }

      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'dasboard-widget-dataset').then(async response => {
       
        if (response.data.total == 0) {
         
          axios.post(process.env.VUE_APP_URL_FEATHERS + 'dasboard-widget-dataset', dataWidget).then(responsePostWidget => {
            console.log("add initial data", responsePostWidget);
          })
        }
        else {
          
          axios.patch(process.env.VUE_APP_URL_FEATHERS + 'dasboard-widget-dataset/' + response.data.data[0]._id, dataWidget).then(responsePatchWidget => {
            console.log("sini bos", responsePatchWidget);
          })

        }



      })



    },

    async updateDataApexChart() {

     
      let InitialdataAnalytic = {
        pelangganBaru: 0,
        pelangganMasuk: 0,
        invoiceTotal: 0,
        paidInvoiceTotal: 0,
      }
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'dashboard-analytic-dataset?$limit=12&ApexChart=true').then(async response => {
       
        if (response.data.total == 0) {
          for (let index = 0; index < 12; index++) {
            axios.post(process.env.VUE_APP_URL_FEATHERS + 'dashboard-analytic-dataset', InitialdataAnalytic).then(responsePostApex => {
              console.log(responsePostApex);
            })

          }
        }
        else {
         
          for (let index = 0; index < 12; index++) {
            let dataAnalytic = {
              pelangganBaru: this.DataSetApePelangganBaruAnalysis[index],
              pelangganMasuk: this.DataSetApePelangganMasukAnalysis[index],
              invoiceTotal: this.DataSetInvoicesAnalysis[index],
              paidInvoiceTotal: this.DataSetInvoicesPaidAnalysis[index]
            }
           
            axios.patch(process.env.VUE_APP_URL_FEATHERS + 'dashboard-analytic-dataset/' + response.data[index]._id, dataAnalytic)

          }
        }
      })
    },

    async CornScheudleRefresh() {

      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'dashboard-analytic-dataset?$limit=12&ApexChart=true')
       await this.loadDataDashboardApex()
      
    },

    async beforeCornStart() {
     
      if (this.growthPelangganBaruAnalysis == null) {
       
        await this.loadDataDashboardApex()

      }

    },
    handleDataFetched(data) {
     
      this.allDataSet = data;
    },

    async getInvoicesDataLocalByClientGrup() {
      
      if (!this.isDataLoaded) {
        this.isDataLoaded = true;
       
        await axios.get(process.env.VUE_APP_URL_FEATHERS + 'dashboard-analytic-dataset?$limit=12&newApexChart=true').then(async (response) => {
          this.DataSetInvoices = response.data.returnDataAllClient.TotalInvoicesBulanan;
          this.DataSetInvoicesPaid = response.data.returnDataAllClient.totalPaidInvoicesBulanan;
          this.allDataSet = response.data; // Set data di sini
          
          // Properti lain...
        });
      }
    },
    




    open() {
      // console.log(localStorage.getItem('user'))
      // console.log('store', store)
    },
    dispac() {
      // this.$store.dispatch('yourModuleName/doSomething')
    }
  },
  
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    
    <loading v-model="isLoading" :active="isLoading" :can-cancel="true" color="red"></loading>
    
    <Stat :invoiceProp="invoicesParent" v-if="invoicesParent"/>
    <div class="row">
      
      <SalesAnalytics v-if="allDataSet" :AllData="allDataSet" />
      <invoicesAnalytics v-if="allDataSet" :AllData="allDataSet"/>

      <div class="col-lg-4">

        <!-- end card-->

        <!-- <SellingProduct /> -->


      </div>

    </div>
    <div class="row">
      <TopUsers />
      <Activity />
      <SocialSource />

    </div>
  </Layout>
</template>
<style>
.square{
  width: 200px;
  height: 200px;
  position: relative;
 
}
.square div {
  position: relative;
  top: 0;
  height: 3.125rem;
  width: 3.125rem;
  background: rgb(255, 255, 255);
  animation: flip 1.8s infinite;
  transform-origin: top right;
}
.square div:nth-child(2){
  animation-delay: 1.5s;
  background: rgb(253, 253, 253);
}
.square div:nth-child(3){
  animation-delay: 1s;
  background: rgb(255, 2, 2);
}



</style>
