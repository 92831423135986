<script>
import countTo from "vue-count-to";
//import axios from 'axios';
const moment = require('moment');

/**
 * Sales-analytics component
 */
export default {
    components: {
        countTo
    },
    props: {
        AllData: {
            type: Object,
        }
    },
    data() {
        return {
           
            series: [],
            chartOptions: {

                chart: {
                    stacked: false,
                    toolbar: {
                        show: false
                    },
                   
                },
                stroke: {
                    width: [0, 2, 4],
                    curve: 'smooth'
                },
                plotOptions: {
                    bar: {
                        columnWidth: '30%'
                    }
                },
                colors: ['#5b73e8', '#84f6fa', '#f1b44c'],
                fill: {
                    opacity: [0.85, 0.25, 1],
                    gradient: {
                        inverseColors: false,
                        shade: 'light',
                        type: "vertical",
                        opacityFrom: 0.85,
                        opacityTo: 0.55,
                        stops: [0, 100, 100, 100]
                    }
                },
                labels: [],
                xaxis: {
                    type: 'datetime',
                    labels: {
                    format: 'MMM'
                    },
                    tooltip: {
                        x: {
                            format: 'DD'
                        }
                    },


                },
                yaxis: {
                    title: {
                        text: 'Per Bulan',
                    },
                    labels: {
                        text: 'MM'
                    },
                },
                tooltip: {
                    shared: true,
                    intersect: false,

                    y: {
                        formatter: function (y) {
                            if (typeof y !== "undefined") {

                                return y.toFixed(0) + " points";
                            }
                            return y;

                        },

                    }
                },
                grid: {
                    borderColor: '#f1f1f1'
                }

            },

            viewTotalInvoice: 0,
            viewTotalPaidInvoices: 0,
            viewTotalInvoiceYear: 0,
            viewTotalInvoicePaidYear: 0,
            DataSetInvoices: [],
            DataSetInvoicesPaid: [],
            konversiDataSet: 0.00,
            allDataSet: [],
            clientGrupDropdown: [],
            labelGroupDropdown: "All",
            isDataLoaded: false,
            holderProps:null,
            holderMountly:null,
            formattedDates: null,
           
            
            
        };
    },



    async created() {
        await this.getInvoicesDataLocalByClientGrup()
        
        
        // this.updateChart1()
        await this.updateChart()

    },

    methods: {

         updateChart() {

            this.series = []
             this.series.push({
                name: 'Total Invoices',
                type: 'column',
                data: this.DataSetInvoices
            })
            
             this.series.push({
                name: 'Total Invoices Terbayar',
                type: 'area',
                data: this.DataSetInvoicesPaid
            })
            
            this.chartOptions = Object.assign({}, this.chartOptions, {
            labels: this.formattedDates.map(date => moment(date).format('MM/DD/YYYY'))
        });
            
        },
       
        async getInvoicesDataLocalByClientGrup() {
            
                this.DataSetInvoices = this.AllData.returnDataAllClient.TotalInvoicesBulanan
                this.DataSetInvoicesPaid = this.AllData.returnDataAllClient.totalPaidInvoicesBulanan
                this.allDataSet = this.AllData
                this.clientGrupDropdown = this.AllData.clientGrupArray
                this.labelGroupDropdown = "All Client Grup"
                this.viewTotalInvoiceYear = parseInt(this.AllData.returnDataAllClient.TotalInvoices)
                this.viewTotalInvoicePaidYear = parseInt(this.AllData.returnDataAllClient.totalPaidInvoices)
                this.konversiDataSet = this.viewTotalInvoicePaidYear / this.viewTotalInvoiceYear * 100
                this.holderMountly = this.AllData.returnDataAllClient.bulan
                
                this.formattedDates = this.AllData.returnDataAllClient.bulan
               // this.$emit('data-fetched', this.allDataSet);
               
             
        },
        async getDataByDropdown(test) {

            this.labelGroupDropdown = test
        }

    },

    watch: {
        lazy:true,
        'labelGroupDropdown': {
            async handler(test) {
                console.log(this.allDataSet);
                for (let index = 0; index < this.allDataSet.arrayHasil.length; index++) {

                    if (this.labelGroupDropdown == this.allDataSet.arrayHasil[index].groupcode) {
                        console.log('isi gaesss',this.allDataSet.arrayHasil[index])
                        this.DataSetInvoices = this.allDataSet.arrayHasil[index].TotalInvoicesBulanan
                        this.DataSetInvoicesPaid = this.allDataSet.arrayHasil[index].totalPaidInvoicesBulanan
                        this.viewTotalInvoiceYear = parseInt(this.allDataSet.arrayHasil[index].TotalInvoices[11])
                        this.viewTotalInvoicePaidYear = parseInt(this.allDataSet.arrayHasil[index].totalPaidInvoices[11])
                        this.konversiDataSet = parseInt(this.viewTotalInvoicePaidYear) / parseInt(this.viewTotalInvoiceYear) * 100
                        this.konversiDataSet = parseInt(this.konversiDataSet)
                        this.updateChart()
                    }
                }
                if (test == "All Client Grup") {
                    
                    this.getInvoicesDataLocalByClientGrup()
                    this.updateChart()

                }

            },
        },
    },

};
</script>

<template>
    <div class="col-md-6" style="min-height: 439px;">
        <div class="card" >
            <div class="card-body">
                <div class="float-end">
                    <b-dropdown variant="white" toggle-class="text-reset p-0" menu-class="dropdown-menu-end"
                        v-bind:text="(labelGroupDropdown == 'All' ? 'All' : labelGroupDropdown)">
                        <template v-slot:button-content>
                            <span class="fw-semibold">Client Grup:</span>
                            <span class="text-muted">
                                {{ labelGroupDropdown }}
                                <i class="mdi mdi-chevron-down ms-1"></i>
                            </span>
                        </template>
                        <b-dropdown-item href="#" @click="getInvoicesDataLocalByClientGrup">All Client
                            Grup</b-dropdown-item>
                        <b-dropdown-item href="#" v-for="clientGrup in clientGrupDropdown" :key="clientGrup"
                            @click="getDataByDropdown(clientGrup)">{{ clientGrup }}</b-dropdown-item>
                    </b-dropdown>
                </div>
                <h4 class="card-title mb-4">Invoice Analytics</h4>

                <div class="mt-1">
                    <ul class="list-inline main-chart mb-0">
                        <li class="list-inline-item chart-border-left me-0 border-0" style="padding-right: 5px;">
                            <h3 class="text-primary">

                                <span data-plugin="counterup">
                                    <countTo :startVal="0" :endVal="viewTotalInvoiceYear" :duration="2000"></countTo>
                                </span>
                                <span class="text-muted d-inline-block font-size-15 ms-1">Total Invoice</span>
                            </h3>
                        </li>
                        <li class="list-inline-item chart-border-left me-0 pe-0">
                            <h3>
                                <span data-plugin="counterup">
                                    <countTo :startVal="0" :endVal="viewTotalInvoicePaidYear" :duration="2000"></countTo>
                                </span>
                                <span class="text-muted d-inline-block font-size-15 ms-1">Invoice Terbayar</span>
                            </h3>
                        </li>
                        <li class="list-inline-item chart-border-left me-0">
                            <h3>
                                <span data-plugin="counterup" class="font-size-22" :class="{
                                    'text-success me-1': konversiDataSet > 0,
                                    'text-danger me-1': konversiDataSet < 0,
                                    'text-info me-1': konversiDataSet === 0
                                }">
                                    <countTo :startVal="0" :endVal="konversiDataSet" :duration="2000" :decimals="2">
                                    </countTo>%
                                </span>
                                <span class="text-muted d-inline-block font-size-15 ms-1"> Persentasi Terbayar</span>
                            </h3>
                        </li>
                    </ul>
                </div>
                <div>
                    <p></p>
                </div>
                <div class="mt-1 custom-apexchart-container"  >
                    <apexchart type="line" class="apex-charts" dir="ltr" height="339" :options="chartOptions"
                        :series="series"></apexchart>
                </div>
            </div>
            <!-- end card-body-->
        </div>
        <!-- end card-->
    </div>




    <!-- end col-->
</template>
<style>
.custom-apexchart-container {
 
 
  overflow: hidden;
}
</style>